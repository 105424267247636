import type { Variants } from 'framer-motion';
import { motion } from 'framer-motion';

import { CircleButton } from '@dandapani/web/ui/circle-button';
import { Icon } from '@dandapani/web/ui/icons';

const cardVariants: Variants = {
  offscreen: {
    opacity: 0,
    y: 0,
  },
  onscreen: {
    y: -64,
    opacity: 1,
    transition: {
      type: 'spring',
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

const viewport = {
  once: false,
  amount: 1,
};

export function AboutDandapani() {
  return (
    <section className="bg-gray-500 py-16 lg:py-32">
      <div className="layout grid auto-rows-auto gap-24 lg:grid-cols-2 lg:grid-rows-1">
        <div className="col-start-1">
          <h3 className="mx-auto mb-16 max-w-96 text-center font-serif text-xl lg:hidden">
            Let’s discover components of a <span className="font-bold italic text-red-500">Happy Life</span>
          </h3>
          <span className="sr-only">
            The interactive element shows a hindu symbol mandala with a first spritual circle: you.
          </span>
          <div
            className="relative m-4 flex aspect-square items-center justify-center rounded-full bg-red-500 lg:sticky lg:top-[calc(var(--header-height)_+_2rem)]"
            aria-hidden
          >
            <span className="absolute left-0 top-0 font-serif font-bold italic text-red-500">(01)</span>
            <motion.div
              className="absolute flex h-24 w-32 -translate-y-16 flex-col items-center justify-end gap-4"
              initial="offscreen"
              whileInView="onscreen"
              viewport={viewport}
              variants={cardVariants}
            >
              <p className="text-center font-serif text-lg text-gray-500">
                This is <span className="font-bold italic">you</span>
              </p>
              <div className="h-12 w-0.5 bg-gray-500" />
            </motion.div>
            <div className="h-8 w-8 rounded-full border-2 border-gray-500" />
          </div>
        </div>
        <div className="lg:col-start-2">
          <h3 className="font-serif text-2.5xl">Dandapani is...</h3>
          <ul className="hide-scrollbar -mx-4 grid w-screen snap-x scroll-px-4 grid-cols-[repeat(4,19rem)] overflow-x-scroll px-4 pt-10 sm:-mx-8 sm:scroll-px-8 sm:px-8 lg:w-full lg:grid-flow-row lg:grid-cols-1 lg:overflow-auto">
            <Li title="A Hindu priest" href="/about">
              Dandapani is a distinguished Hindu priest known for his deep spiritual wisdom and his ability to translate
              complex teachings into practical guidance for modern seekers.
            </Li>
            <Li title="A Teacher" href="/program">
              Dandapani serves as an inspiring teacher, skillfully sharing his profound understanding of mindfulness and
              focus to empower students in their pursuit of personal and spiritual development.
            </Li>
            <Li title="An Author" href="/book">
              Through his writings, Dandapani skillfully distills intricate spiritual concepts into accessible insights,
              empowering readers to embark on a journey of self-discovery and growth.
            </Li>
            <Li title="A Speaker" href="/speaker">
              Dandapani is a captivating speaker who artfully guides his audience towards mindfulness and focus,
              offering transformative insights for personal growth.
            </Li>
          </ul>
        </div>
      </div>
    </section>
  );
}

function Li({ title, children, href }: React.PropsWithChildren<{ title: string; href: string }>) {
  return (
    <li className="relative border-t-2 border-gray-600 pr-8 pt-10 lg:border-l-2 lg:border-t-0 lg:pb-16 lg:pl-20 lg:pt-0 lg:last:border-transparent lg:last:pb-0">
      <div className="absolute -top-3.5 left-0 bg-gray-500 lg:-left-3.5 lg:top-0">
        <Icon className="h-7 w-7 text-red-500" icon="flower" />
      </div>
      <div className="flex flex-col space-y-4">
        <h4 className="font-serif text-1.5">{title}</h4>
        <p className="text-sm">{children}</p>
        <CircleButton href={href} className="self-end">
          Learn More
        </CircleButton>
      </div>
    </li>
  );
}
